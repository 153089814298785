<template>
    <div class="main-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="收款账户" name="first">
                <AccountList></AccountList>
            </el-tab-pane>

            <el-tab-pane label="账单数据" name="second">
                <el-form inline size="small" label-width="100px">
                    <el-form-item label="账单期数：">
                        <el-select class="input-width" v-model="filter.routingMonth" placeholder="请选择账单期数">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option v-for="item in monthList" :key="item" :label="item" :value="item"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button icon="el-icon-search" type="primary" @click="searchBill">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="filter.list" v-loading="tableLoading" border>
                    <el-table-column prop="routingMonth" label="账单期数" width="160"></el-table-column>
                    <el-table-column prop="supplierAmount" label="供应商总成本"></el-table-column>
                    <el-table-column prop="techniqueAmount" label="技术服务费"></el-table-column>
                    <el-table-column prop="spreadAmount" label="推广服务费"></el-table-column>
                    <el-table-column prop="storageAmount" label="仓储服务费"></el-table-column>
                    <el-table-column prop="appreciationAmount" label="增值服务费"></el-table-column>
                    <el-table-column prop="createTime" label="报表生成时间" min-width="160"></el-table-column>
                    <el-table-column prop="modifyTime" label="报表更新时间" min-width="160"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="140">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.routingStatus === 1" size="mini" type="text" @click="showDetailFunc(scope)">查看账单</el-button>
                            <el-button v-else size="mini" type="text" @click="showDetailFunc(scope)">去分账</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination style="text-align: right; padding: 15px" :current-page="filter.pageNo" :page-size="filter.pageSize"
                               :total="filter.total" :page-sizes="filter.sizes" @size-change="pageSizeChange" @current-change="currentChange"
                               background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
            </el-tab-pane>
        </el-tabs>

        <el-dialog title="账单详情" :visible.sync="billDetailFlag" width="60%" top="35px" :show-close="false" @closed="clearDataFunc">
            <BillDetail v-if="billDetailData" :formData="billDetailData" @closedLayer="closedLayer" @changeNeedReload="changeNeedReload"></BillDetail>
        </el-dialog>
    </div>
</template>

<script>
    import {getQueryRoutingBillList, getQueryRoutingMonthList} from '@/api/finance';
    import BillDetail from './billDetail';
    import AccountList from './accountList';

    export default {
        name: 'collectionBill',
        components: {
            AccountList,
            BillDetail,
        },
        data() {
            return {
                activeName: 'first',
                monthList: [], //月份
                tableLoading: false,
                filter: {
                    routingMonth: '',
                    pageNo: 1,
                    pageSize: 10,
                    total: 0,
                    list: [],
                },
                billDetailFlag: false,
                billDetailData: null,
                needReload: false, //是否需要刷新页面
            }
        },
        mounted() {
            this.getMonthList();
        },
        methods: {
            async getMonthList() {
                let { success, data } = await getQueryRoutingMonthList();
                if(success) {
                    this.monthList = data || [];
                }
            },
            handleClick(tab, event) {
                if(tab.name === 'second') {
                    this.searchBill();
                }
            },
            // 是否需要刷新页面
            changeNeedReload(flag) {
                this.needReload = flag;
            },
            // 关闭弹窗
            closedLayer() {
                this.billDetailFlag = false;
            },
            // 清除选择内容
            clearDataFunc() {
                this.billDetailData = null;
                if(this.needReload) {
                    this.searchBill();
                }
            },
            // 账单详情
            showDetailFunc({row}) {
                this.billDetailFlag = true;
                this.billDetailData = {...row};
            },
            searchBill() {
                this.filter.pageNo = 1;
                this.getList();
            },
            pageSizeChange(size) {
                this.filter.pageSize = size;
                this.getList();
            },
            currentChange(page) {
                this.filter.pageNo = page;
                this.getList();
            },
            // 查询账单列表
            async getList() {
                this.tableLoading = true;
                let params = {
                    routingMonth: this.filter.routingMonth,
                    pageNo: this.filter.pageNo,
                    pageSize: this.filter.pageSize,
                };
                let { success, data } = await getQueryRoutingBillList(params);
                if (success) {
                    this.filter.list = data.records || [];
                    this.filter.total = data.total || 0;
                }
                setTimeout(() => {
                    this.tableLoading = false;
                }, 300);
            },
        }
    }
</script>