<template>
	<div>
		<el-form inline size="small" label-width="100px">
			<el-form-item>
				<buttonPermissions datas="authentication">
					<el-button type="primary" @click="toAdd">新增账户</el-button>
				</buttonPermissions>
				<buttonPermissions datas="separateAccounts">
					<el-button type="primary" style="margin-left:10px" @click="openDialog(1)">分账</el-button>
				</buttonPermissions>
				<buttonPermissions datas="withdrawal">
					<el-button type="primary" style="margin-left:10px" @click="openDialog(2)">提现</el-button>
				</buttonPermissions>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="table-container">
			<el-table :data="broadData" v-loading="loading" ref="multipleTable" border>
				<el-table-column label="账户类别">
					<template slot-scope="scope">
						<div>{{scope.row.accountType === 3 ? '个人账户' : '企业账户'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="companyName" label="账号名称" width="240"></el-table-column>
				<el-table-column prop="bankName" label="开户支行"></el-table-column>
				<el-table-column prop="parentBankName" label="银行名称"></el-table-column>
				<el-table-column prop="userName" label="开户人姓名"></el-table-column>
				<el-table-column prop="accountNo" label="银行卡号">
					<template slot-scope="scope">
						<div>{{scope.row.accountType === 3 ? scope.row.cardNo : scope.row.accountNo}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="phone" label="开户人手机号"></el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
                        <span style="color:rgba(2, 167, 240, 0.8);" @click="signContract(scope.row)">电子签约</span>
						<buttonPermissions :datas="'collectionAccountDel'">
							<span style="color:#f00;margin-left:10px;" @click="routingDelete(scope.row)">解绑</span>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分账 -->
		<el-dialog :title="dialogType == 1?'选择账户分账':'提现'" :visible.sync="headOrderVisible" >
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="分润方式：" v-if="dialogType == 1">
					<el-radio v-model="ruleForm.authenticationType" :label="0">按百分比分润</el-radio>
					<el-radio v-model="ruleForm.authenticationType" :label="1">按固定金额分润</el-radio>
				</el-form-item>
				<el-form-item :label="dialogType == 1 ?'分润规则：':'选择账户'" :rules="[{required:true}]">
					<div class="flex" v-for="(use,index) in userList" :key="index" style="margin-bottom: 10px; width: 580px">
						<div style="flex: 1;">
							<el-select v-model="use.id" placeholder="请选择" style="width: 300px;" @change="selectAccountFunc">
								<el-option v-for="item in broadData" :key="item.id" :label="item.companyName" :value="item.bizUser"></el-option>
							</el-select>
							<el-input v-model="use.num" style="width:100px;margin-left:10px;">
							</el-input> {{ruleForm.authenticationType == 0 && dialogType == 1? '%' : '元'}}
							<span v-if="index != 0" @click="delUser(index)" style="font-size:12px;color:red;margin-left:10px;">删除</span>
						</div>
						<div v-if="use.id">账户余额：{{ use.balance || 0 }}</div>
					</div>
					<br/>
					<el-button type="primary" @click="addUser" v-if="userList.length < 10">添加账户</el-button>
				</el-form-item>
				<div style="text-align: center;margin-top: 50px;">
					<el-button type="primary" @click="sureSends()" style="width:120px" :loading="saveLoading">确定</el-button>
					<el-button @click="headOrderVisible = false" style="width:120px" >取消</el-button>
				</div>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
    import {applicationTransfer, routingDelete, selectList, signContract, withdrawApply, getQueryBalance} from '@/api/finance'
    import buttonPermissions from '@/components/buttonPermissions';
    export default {
		components: {
			buttonPermissions
		},
		data () {
			return {
				broadData: [],
				loading: false,
				headOrderVisible: false,
				ruleForm: {
					authenticationType: 0

				},
				saveLoading: false,
				dialogType:1,
				userList:[{id:'',num:null, balance: 0}],
			}
		},
		created () {
			this.getList();
		},
		methods: {
			//添加
			toAdd() {
				this.$router.push({ path: '/finance/collectionAccount/authentication'});
			},
			// 获取列表
			async getList () {
				try {
					this.loading = true

					let result = await selectList();
					result.data.map(item => {
						if (item.accountType == 2) {
							item.userName = item.legalName;
						}else{
							item.userName = item.name;
						}
					})
					this.broadData = result.data || [];

				} finally {
					this.loading = false
				}

			},
			//打开弹窗
			openDialog(type){
				this.dialogType = type;
				this.headOrderVisible = true;
				this.userList = [{id:'',num:null, balance: 0}];
			},
			//添加账户
			addUser(){
				this.userList.push({id:'',num:null, balance: 0});
			},
			//删除账户
			delUser(index){
				console.log(index);
				this.userList.splice(index,1);
			},
			//弹窗确定
			async sureSends(){
				let _this = this;
				let sum = 0;
				let tempArray = [];
				let op = false;
				this.saveLoading = true;
				this.userList.map(item =>{
					if (!item.id || !item.num) {
						op = true;
						return false;
					}
					if (_this.dialogType == 1) {
						if (_this.ruleForm.authenticationType == 0) {
							sum += item.num;
						}
						tempArray.push({amount:item.num,targetBizUserId:item.id});
					}else if (_this.dialogType == 2) {
						_this.broadData.map(item1 =>{
							if (item1.bizUser == item.id) {
								tempArray.push({
									amount:item.num,
									bizUserId:item.id,
									bankCardNo:item1.accountType == 3 ? item1.cardNo : item1.accountNo,
									type:item1.accountType == 3 ? 0 : 1
								});
							}
						});
					}
				});
				if (sum > 100) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '分润百分比总和不能超过100%！'
					});
					return;
				}
				if (op) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '分润规则不能为空！'
					});
					return;
				}
				console.log(tempArray);
				try {
					let result;
					if (_this.dialogType === 1) {
						result = await applicationTransfer({transfer:tempArray,type:_this.ruleForm.authenticationType});
					}else{
						result = await withdrawApply({request:tempArray});
					}
					if (result.success) {
						if (result.data.fail && result.data.fail.length) {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.data.fail[0].subMsg
							});
							this.$nextTick(function(){
								this.$message({
									showClose: true,
									type: 'error',
									message: result.data.msg.msg
								});
							})
						}else{
							this.$message({
								showClose: true,
								type: 'success',
								message: result.data.msg.msg
							});
						}

						this.headOrderVisible = false;
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} finally {
					this.saveLoading = false;
				}
			},
			//解绑
			routingDelete(row){
				this.$confirm('解绑成功后，不可恢复，是否确认确认继续解绑?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(async () => {
					let result = await routingDelete({id:row.id});
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '解绑成功！'
						});
						this.getList();
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}

				}).finally(() => {
					console.log(2);
				})
			},
			//电子签约
			async signContract(row){
				try {
					let result;
					if (row.accountType === 3) {
						result = await signContract({phone:row.phone});
					}else{
						result = await signContract({phone:row.legalPhone});
					}
					if (result.success) {
						window.open(result.data);
					}else{
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg
						});
					}
				} finally {
					this.saveLoading = false;
				}
			},
			//选择账户
			selectAccountFunc(e) {
				let row = null;
				this.userList.filter((item, index) => {
					if(item.id === e) {
						row = {...item, index};
					}
				});
				this.getBalanceFunc(e, row);
			},
			//查询选择账户余额
			async getBalanceFunc(id, row) {
				let { data } = await getQueryBalance({bizUserId: id});
				if(data) {
					row.balance = data.allAmount || 0;
					this.userList.splice(row.index,1, {...row});
				}
			},
		}
	}
</script>
